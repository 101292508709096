import React from "react"
import { graphql } from "gatsby"

import Cartouche from "../components/cartouche"
import CollapsingCard from "../components/CollapsingCard"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function PageTemplate({ data, pageContext }) {
  if (!data) return null

  const { title, featuredImage } = data.wpPage

  const { equipe } = data.wpPage.fieldsPageContact;

  const { address, coordonnees, map, mapLink } = data.wpPage.fieldsPageContact.contactBox;

  return (
    <Layout featuredImage={featuredImage}>
      <Seo title={title} />
      <div className="container">
        <div className="row">
          <div className="column-left">

          </div>
          <div className="column-right">
            <div className="mt-negative">
              <Cartouche classes="noPadding">
                <div className="row">
                  <div className="column-left gutter">
                    <h1>{title}</h1>
                    <div>
                      <p>
                        <strong className="mb">
                          atelier<br />
                          d’architecture<br />
                          DMart sàrl
                        </strong>
                      </p>
                      <div dangerouslySetInnerHTML={{ __html: address }}></div>
                      <div className="mb-0" dangerouslySetInnerHTML={{ __html: coordonnees }}></div>
                    </div>
                  </div>
                  <div className="column-right noGutter">
                    <a href={mapLink} className="d-flex">
                      <img className="img-cover" srcSet={map.srcSet} alt="" />
                    </a>
                  </div>
                </div>
              </Cartouche>
            </div>
          </div>
        </div>
        <div className="row mt mb">
          <div className="column-left">
            <h2>équipe</h2>
          </div>
          <div className="column-right">
            {
              equipe.map(function (people, index) {
                return (
                  <CollapsingCard
                    key={index}
                    content={people.description}
                    title={people.name}
                    subtitle={people.job}
                    featuredImage={people.photo}
                    isOpened={true}></CollapsingCard>
                )
              })
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query getPageContact {
    wpPage(slug: {eq: "contact"}) {
      id
      title
      slug
      featuredImage {
       node {
          imageCustomFields {
            position
          }
          localFile {
            publicURL
          }
        }
      }
      fieldsPageContact {
        equipe {
          description
          fieldGroupName
          job
          name
          photo {
            localFile {
              publicURL
            }
            mediaDetails {
              sizes {
                name
                sourceUrl
              }
            }
          }
        }
        contactBox {
          address
          coordonnees
          mapLink
          map {
            srcSet
          }
        }
      }
    }
  }
`
